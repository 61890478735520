@import "~bootstrap/scss/bootstrap";

$background-color: #336887;

.enteteaccr {
	text-shadow: 5px 5px 3px rgba(0,0,0, 0.3); 
	margin-left: 0px;
	font-weight: bold;
	font-size: 1.5em;
	display:inline-block; 
	padding: 10px; padding-left: 60px; 
	background-image: url('/img/mail-filters-apply64.png');
	background-repeat: no-repeat;
	background-size: 50px;
	background-position: left;
	color: #fff;
}

.navbar {background-color: #043a64;}
.nav-link {
	
	font-weight: bold;
	font-family: 'Roboto';
	font-family: 'Bubblegum Sans', cursive;
	
	margin-right: 20px;
	
	font-weight: normal;
  font-size: 200%;
  
  text-decoration: none;
  color: #031D44;
  padding: 20px 0px;
  margin: 0px 20px;
  display: inline-block;
  position: relative;
  opacity: 0.75;
}
.nav-link-ltr::before {
  width: 0%;
  bottom: 10px;
}
.nav-link::before {
  transition: 300ms;
  height: 5px;
  content: "";
  position: absolute;
  background-color: #ffffff;
}

.nav-link-ltr:hover::before {
  width: 100%;
}

.nav-link-fade-up::before {
  width: 100%;
  bottom: 5px;
  opacity: 0;
}

.nav-link-fade-up:hover::before {
  bottom: 10px;
  opacity: 1;
}

.nav-link-grow-up::before {
  height: 0%;
  width: 100%;
  bottom: 0px;
}

.nav-link-grow-up:hover::before {
  height: 5px;
}


.nav-link.active {
    color : white !important;
}
        	
        	div.footdiv ul li a:hover { color: #333; text-decoration: none; }
        	.footdiv ul li a { color: #333; text-decoration: none; }
        	.footdiv ul li {  list-style-image:none; outline-style: none; line-height: 2em; font-size: 0.8em; }
        	.footdiv ul {
        		list-style: none;
        	}
        	
        	.footdiv {
        		
        		
        		text-align: left;
        		
        	}
        	.foottitre {
        		font-weight: bold;
        		color: #000;
        	}
        	 
a {
	text-decoration: none;
}
body, html {
	color: #fff;
	height: 100%;
	margin: 0;
	padding: 0;
	font-size: 15px;
	font-family: 'Roboto', sans-serif;
	background-color: $background-color;
}
label {
	color: #000;
}
textarea, input {
	background-color: #eee;
	
}
textarea:focus, input:focus {
	background-color: #ddd;
	color: #000;
	
}

.titreaccroche {
	font-weight: bold;
	font-size: 1.4em;
	background-color: #336887;
	font-family: 'Roboto';
	margin-top:10px;
	margin-bottom: 20px;
	text-shadow: 5px 5px 5px rgba(0,0,0, 0.5);
}

.lien {
	color:#ff0;
	font-weight: bold;			
}
.lien:hover {
	color:#ff0;
	font-weight: bold;			
}
		
/* page description */

.rubrique::before {
	font-family: "Font Awesome 5 Free";
	content: "\f14a";
	font-variant: normal;
	font-weight: 900;
	margin-right: 10px;
}
.rubrique {
	clear: both;
	
	/*padding-top: 20px;*/
	padding-left: 25px;
	
	font-size: 1.1em;
	font-weight: bold;
	/*
	background-image: url('/img/arrow-right-3-24x24.png');
	background-repeat: no-repeat;*/
	
	color: #fff;
	background-color: #336887;
	/*width: 100%;*/
	padding: 5px;
	/* padding-left: 40px; */
	background-position: 5px 5px;
	border: 0px solid #aaa;
	margin-bottom: 5px;
	margin-top: 0px;
	
	text-shadow: 2px 2px 2px rgba(0,0,0, 0.5);
}
.desctxt {
	display: block;
	margin-left: 30px;
	margin-bottom: 15px;
	padding: 10px;
	font-size: 100%;
	font-weight: normal;
	color: #222;
	
}
.imglettrine {
	float: left;
	padding: 15px;
}
.iconavigateur {height: 48px;}

/* fin page description */

#menulittle {
	position: absolute;
	border: 3px solid #ccc;
	background-color: #015b8e;
	width: 150px;
	right: 0;
	font-weight: bold;
	font-size: 1em;
	text-shadow: 2px 2px 2px #000;
}
		
#header {
	position: relative; /*position: fixed;*/
	background-color: #336887;
	background-image: url('/img/entete.png');
	background-repeat: repeat-x;
	background-position: top;
	height:130px;
	top:0;
	left:0;
	right:0;
	z-index: 999;
	color: #fff;
	border: 0px solid #ff0;
}

#footer {
	text-align: center;
	position: relative;
	background-color: #555;
	color: #333;
	overflow: auto;
	   
	bottom: 0;
	left: 0;
	right: 0;
	
	background: #e2e2e2;
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2UyZTJlMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top,  #e2e2e2 0%, #ffffff 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#e2e2e2), color-stop(100%,#ffffff));
	background: -webkit-linear-gradient(top,  #e2e2e2 0%,#ffffff 100%);
	background: -o-linear-gradient(top,  #e2e2e2 0%,#ffffff 100%);
	background: -ms-linear-gradient(top,  #e2e2e2 0%,#ffffff 100%);
	background: linear-gradient(to bottom,  #e2e2e2 0%,#ffffff 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#ffffff',GradientType=0 );
}

#content {
	padding:0px 0 0 0;
	background-color: #336887;
	
}
#contentin {
	margin: 0 auto 10px; width: 1000px; color: #fff;
	
}

.centered {
	margin: 0 auto 0px;
	width: 1000px;
	
	display: block;
	border: 0px solid #0f0;
}



	#left {
		border: 0px solid #110;
		float: left;
		width: 48%;
		
		display: inline-block;
		position: relative;
	}
	#right {
		border: 0px solid #110;
		float: right;
		width: 48%;
		text-align: left;
		color: #fff;
	}
	#foo2ter {
		/*margin: 0 auto 10px;
		width: 1000px;
		color: #aaa; background-color: #fff; bottom: 0;
		position: relative;
		*/
		
		color: #aaa; background-color: #ddd; bottom: 0;
		/*height: 100px;*/
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.topbtn {
		padding: 10px;
		padding-right: 20px;
		padding-left: 20px;
	
		margin-right: 10px;
		text-shadow: 1px 1px 2px rgba(0,0,0, 0.5); 
		font-size: 120%;
		background-color: transparent;
		color: #fff;
		border: 0px solid #ff0;
		cursor: pointer;
		
		border: 0px solid #ff0;
		
		font-weight: bold;
	}
	.topbtn:hover {
		background-color: rgba(250,250,250, 0.5);
		/*
			background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPHJhZGlhbEdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iNzUlIj4KICAgIDxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNlMmUyZTIiIHN0b3Atb3BhY2l0eT0iMC4yIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMC4yIi8+CiAgPC9yYWRpYWxHcmFkaWVudD4KICA8cmVjdCB4PSItNTAiIHk9Ii01MCIgd2lkdGg9IjEwMSIgaGVpZ2h0PSIxMDEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
			background: -moz-radial-gradient(center, ellipse cover,  rgba(226,226,226,0.2) 0%, rgba(255,255,255,0.2) 100%);
			background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(226,226,226,0.2)), color-stop(100%,rgba(255,255,255,0.2)));
			background: -webkit-radial-gradient(center, ellipse cover,  rgba(226,226,226,0.2) 0%,rgba(255,255,255,0.2) 100%);
			background: -o-radial-gradient(center, ellipse cover,  rgba(226,226,226,0.2) 0%,rgba(255,255,255,0.2) 100%);
			background: -ms-radial-gradient(center, ellipse cover,  rgba(226,226,226,0.2) 0%,rgba(255,255,255,0.2) 100%);
			background: radial-gradient(ellipse at center,  rgba(226,226,226,0.2) 0%,rgba(255,255,255,0.2) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33e2e2e2', endColorstr='#33ffffff',GradientType=1 );
*/

	}
	.topbtn:active {
		background-color: rgba(200,200,200, 0.5);
		color: #ddd;
	}
	
	.accroche {
		font-weight: bold;
		font-size: 1.5em;
		color: #252525;
		
		font-family: 'Source Sans Pro', sans-serif;
		display: inline-block;
	}
	.accrochedesc {
		margin-left: 50px;
		display: inline-block;
		font-weight: normal;
		font-size: 1.0em;
		color: #444;
	}
	#btnmenulittle {
		display: none;
	}
	
	.topbtnlittle {
		color: #eee;
		padding: 10px;
		display: inline-block;
	}
	.topbtnlittle:hover {
		color: #fff;
		padding: 10px;
		display: inline-block;
	}

	.demochampcourt {
		width: 70%;
	}
	.demochamplong {
		width: 100%;
	}

	